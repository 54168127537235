// @font-face {
//     font-family: 'Poppins';
//     src: url('/fonts/poppins-v15-latin-300.woff2') format('woff2'),
//          url('/fonts/poppins-v15-latin-300.woff') format('woff');
//     font-weight: 300;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('/fonts/poppins-v15-latin-regular.woff2') format('woff2'),
//          url('/fonts/poppins-v15-latin-regular.woff') format('woff');
//     font-weight: 400;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('/fonts/poppins-v15-latin-500.woff2') format('woff2'),
//          url('/fonts/poppins-v15-latin-500.woff') format('woff');
//     font-weight: 500;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('/fonts/poppins-v15-latin-600.woff2') format('woff2'),
//          url('/fonts/poppins-v15-latin-600.woff') format('woff');
//     font-weight: 600;
//     font-display: swap;
// }

$font-subset: U+22,U+25-3F,U+41-5F,U+61-7D;

@font-face {
    font-family: 'Poppins';
    src: url('/fonts/d3r-poppins-light-subset.woff2') format('woff2'),
         url('/fonts/d3r-poppins-light-subset.woff') format('woff');
    font-weight: 300;
    font-display: swap;
    unicode-range: $font-subset;
}

@font-face {
    font-family: 'Poppins';
    src: url('/fonts/d3r-poppins-light-italic-subset.woff2') format('woff2'),
         url('/fonts/d3r-poppins-light-italic-subset.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    unicode-range: $font-subset;
}

@font-face {
    font-family: 'Poppins';
    src: url('/fonts/d3r-poppins-regular-subset.woff2') format('woff2'),
         url('/fonts/d3r-poppins-regular-subset.woff') format('woff');
    font-weight: 400;
    font-display: swap;
    unicode-range: $font-subset;
}

@font-face {
    font-family: 'Poppins';
    src: url('/fonts/d3r-poppins-regular-italic-subset.woff2') format('woff2'),
         url('/fonts/d3r-poppins-regular-italic-subset.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    unicode-range: $font-subset;
}

@font-face {
    font-family: 'Poppins';
    src: url('/fonts/d3r-poppins-medium-subset.woff2') format('woff2'),
         url('/fonts/d3r-poppins-medium-subset.woff') format('woff');
    font-weight: 500;
    font-display: swap;
    unicode-range: $font-subset;
}

@font-face {
    font-family: 'Poppins';
    src: url('/fonts/d3r-poppins-medium-italic-subset.woff2') format('woff2'),
         url('/fonts/d3r-poppins-medium-italic-subset.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
    unicode-range: $font-subset;
}

@font-face {
    font-family: 'Poppins';
    src: url('/fonts/d3r-poppins-semibold-subset.woff2') format('woff2'),
         url('/fonts/d3r-poppins-semibold-subset.woff') format('woff');
    font-weight: 600;
    font-display: swap;
    unicode-range: $font-subset;
}
