.page-intro {
    max-width: 547px;

    @include breakpoint(tablet) {
        max-width: none;
    }

    &--center {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    &--intro--narrow {
        max-width: 447px;
    }

    &__subtitle {
        @include small-title;
        margin-bottom: 15px;
    }

    &__title {
        @include large-title;
    }

    &__text {
        @include medium-title;
        margin-top: 20px;

        @include breakpoint(tablet) {
            @include font(17px, 28px);

            padding: 0 24px;

            .page-intro--has-image & {
                padding: 0;
            }
        }
    }

    &__cta {
        margin-top: 25px;
    }
}

.page-intro--has-image {
    display: flex;
    align-items: flex-end;
    max-width: none;
    text-align: left;
    margin-bottom: 140px;

    @include breakpoint(tablet) {
        display: block;
        margin-bottom: 0;
        text-align: center;
    }

    > * {
        flex: 0 0 50%;
    }
}

.page-intro__content {

    @include breakpoint(tablet) {
        @include margin(bottom, 10px);

        // text-align: center;
    }
}

.page-intro__image {
    position: relative;
    margin-top: -100%;
    padding-left: $site-gutter;
    text-align: center;

    @include breakpoint(tablet) {
        margin-top: 0;
        padding-left: 0;
        transform: none;
    }

    img {
        max-width: 100%;
        height: auto;

        @include breakpoint(tablet) {
            margin: 0 auto;
        }
    }
}

.page-intro__image--laptop {
    padding-left: 0; // let the laptop spill out
}
