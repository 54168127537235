/// Hide an element safely, i.e. the element is not visible to the user, but is visible to a screen reader
///
/// @param {Boolean} $retain Do we want to keep the element where it is? e.g. hide a checkbox but keep it in place on the page
/// @author Joe Adcock <joe@d3r.com>

@mixin hideSafely($retain: false) {
    @if $retain {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    } @else {
        position: absolute;
        top: -100vh;
        left: -100vw;
    }
}
