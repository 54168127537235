.wrap {
    margin: 0 auto;
    width: $site-width;
    max-width: $site-max-width;
    padding: 0 $site-gutter;

    @include breakpoint(tablet) {
        padding: 0 $site-gutter--mobile;
    }
}

.wrap--medium {
    max-width: 1030px + $site-gutter * 2;
}

.wrap--medium-narrow {
    max-width: 870px + $site-gutter * 2;
}

.wrap--narrow {
    max-width: 607px + $site-gutter * 2;
}

.wrap--flush {
    padding-left: 0;
    padding-right: 0;
}

@each $name, $breakpoint in $sizes {
    .wrap--#{$name}-flush {
        @include breakpoint($breakpoint) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

