.benefit {
    padding-left: 10px;
    padding-right: 10px;

    @include breakpoint(tablet) {
        padding-left: 0;
        padding-right: 0;
    }

    &__icon {
        max-width: 180px;
        height: auto;
        margin: 0 auto 25px;
    }

    &__title {
        @include medium-title;
        margin-bottom: 10px;
    }

    &__description {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(tablet) {
            max-width: none;
        }
    }
}
