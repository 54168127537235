/// Set the value of a numeric property at each breakpoint, and consider the prescence of admin/debug bars
///
/// @param {Array} $value Array of numeric values, one per breakpoint
/// @param {String} $property CSS property
/// @author Joe Adcock <joe@d3r.com>

@mixin allowForBars($value: 0, $property: top) {
    $debugHeight: 20px;
    $adminHeight: 44px;

    $value--default: 0;
    $value--tablet: 0;
    $value--phablet: 0;

    @if type-of($value) == map {
        @if type-of(map-get($value, default)) == number {
            $value--default: map-get($value, default);
        } @else if map-get($value, default) == null {
            $value--default: false;
        }
        @if type-of(map-get($value, tablet)) == number {
            $value--tablet: map-get($value, tablet);
        } @else if map-get($value, tablet) == null {
            $value--tablet: false;
        } @else {
            $value--tablet: $value--default;
        }
        @if type-of(map-get($value, phablet)) == number {
            $value--phablet: map-get($value, phablet);
        } @else if map-get($value, phablet) == null {
            $value--phablet: false;
        } @else {
            $value--phablet: $value--tablet;
        }
    } @else if type-of($value) == number {
        $value--default: $value;
        $value--tablet: $value;
        $value--phablet: $value;
    }

    @if $value--default {
        #{$property}: $value--default;
    }

    @if $value--tablet {
        @include breakpoint(tablet) {
            #{$property}: $value--tablet;
        }
    }

    @if $value--phablet {
        @include breakpoint(phablet) {
            #{$property}: $value--phablet;
        }
    }

    .body--debug & {
        @if $value--default {
            #{$property}: $value--default + $debugHeight;
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet;
            }
        }
    }

    .body--admin & {
        @if $value--default {
            #{$property}: $value--default + $adminHeight;
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet + $adminHeight;
            }
        }

        // @if $value--phablet {
        //     @include breakpoint(phablet) {
        //         #{$property}: $value--phablet;
        //     }
        // }
    }

    .body--debug.body--admin & {
        @if $value--default {
            #{$property}: $value--default + $debugHeight + $adminHeight;
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet + $adminHeight;
            }
        }

        // @if $value--phablet {
        //     @include breakpoint(phablet) {
        //         #{$property}: $value--phablet;
        //     }
        // }
    }
}
