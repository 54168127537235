html {
    font-size: 100%;
    text-size-adjust: 100%;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    background-color: $c-white;
    @include rem(15px);
    @include apply-map($f-primary);
    color: $f-primary-color;
    min-width: 320px;
    letter-spacing: 0.5px;
    hyphens: none;
}

html #debug-bar {
    width: 100%;
    z-index: 30;
    height: 20px;
    top: 0 !important;
    position: fixed;

    @include breakpoint(tablet) {
        display: none !important;
    }
}

html [id] {
    // Allow for debug, header and a bit of spacing
    scroll-margin-top: 75px + 10px
}

.body--debug [id] {
    // Allow for debug, header and a bit of spacing
    scroll-margin-top: 20px + 75px + 10px
}

.body--debug.body--admin #debug-bar {
    top: 45px !important;
}

html #debug-holder {
    position: sticky;

    @include breakpoint(tablet) {
        display: none !important;
    }
}

html #admin-bar {
    position: sticky;
    top: 0;
}

#outer-wrapper {
    // overflow: hidden;
    // position: relative;
}


#admin-bar a {
    line-height: 1;
}
