.sitemap-list {
    @include font(18px, 26px);
    margin-left: 2px;

    a {
        color: $c-black;
        text-decoration: none;
        vertical-align: middle;
    }

    li {
        list-style: none;
        vertical-align: middle;

        &:before {
            content: "-";
            display: inline-block;
            vertical-align: middle;
            line-height: 12px;
        }
    }

    &__title {
        @include medium-title;
        @include mediumText;
        margin-bottom: 10px;

        a {
            color: $c-black;
            text-decoration: none;
        }
    }

    &__sub-title {
        @include medium-title;
        @include font(12px, 18px);
        display: inline-block;
        vertical-align: middle;
        white-space: break-spaces;
    }

    &__item {
        @include medium-title;
        white-space: nowrap;

        a {
            color: $c-black;
            text-decoration: none;
            display: inline-block;
            padding: 2px 0px;

            &:hover {
                color: $c-brand;
            }
        }
    }

    &--full-width {
        column-count: 5;

        @include breakpoint(tablet) {
            column-count: 1;
        }
    }
}
