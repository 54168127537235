/// Grid vertical gutters
///
/// @param {Number} $gutter-y vertical spacing
/// @author Joe Adcock <joe@d3r.com>

@mixin grid-gutter-vertical($gutter-y:$grid-gutter-v) {
    > * {
        margin-bottom: $gutter-y;
    }
    // Use the correct negative margin for flush bottom
    &.grid--flush-bottom {
        margin-bottom: -($gutter-y);
    }
    &.grid--flush-bottom-tab {
        margin-bottom: -($gutter-y);
    }
}
