.clients-list {
    margin: 90px 0;

    @include breakpoint(tablet) {
        margin: $s-vertical--medium 0;
    }

    &__title {
        @include large-title();
        @include margin(bottom, 25px);

        @include breakpoint(tablet) {
            @include font(21px, 25px);
            @include margin(bottom, 16px);

            text-align: center;
            letter-spacing: 0.4px;
        }
    }

    .grid {
        margin-left: -26px;

        @include breakpoint(tablet) {
            margin-bottom: 20px;
            margin-left: -15px;
        }

        > * {
            margin-bottom: 60px;
            padding-left: 26px;

            @include breakpoint(tablet) {
                margin-bottom: 20px;
                padding-left: 15px;
            }

            &:nth-last-child(-n+3) {
                margin-bottom: 60px - 15px;

                @include breakpoint(tablet) {
                    margin-bottom: 20px;
                }
            }
        }
    }

    &__footer {
        text-align: center;
    }
}
