// Setting the transition on img[data-src] doesn't actually work
// but setting it on img does
// is this because there is a delay between removing "lazy" and adding "lazyloaded"?

// img[data-src] {
//     transition: opacity 333s cubic-bezier(.4,0,.22,1);
// }

img {
    width: 100%;
    height: auto;
    transition: opacity 333ms cubic-bezier(.4,0,.22,1);
}

img.lazy {
    opacity: 0;
}

img.lazyloaded {
    opacity: 1;
}
