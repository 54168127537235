// Titles
@mixin page-title {
    @include apply-map($f-title);
    @include font(25px, 33px);
}

@mixin large-title {
    @include apply-map($f-title);
    @include font(25px, 33px);
}

@mixin medium-title {
    @include apply-map($f-title);
    font-weight: 400;
    @include font(18px, 30px);
}

@mixin small-title {
    @include apply-map($f-title);
    font-weight: 400;
    @include font(14px, 30px);
}
