.band {
    margin: 50px 0;
}

.band--medium {
    margin: 25px 0;
}

.band--tall {
    margin: 75px 0;
}

.band--flush {
    margin: 0;
}

.band--flush-top {
    margin-top: 0;
}

.band--flush-bottom {
    margin-bottom: 0;
}

.band--listing {
    margin: 100px 0 170px;

    @include breakpoint(tablet) {
        margin: 45px 0 60px;
    }
}
