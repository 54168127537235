/// Remove white space from space charecters in html etc
/// @author Brendan Patterson <brendan@d3r.com>

@mixin remove-white-space {
    font-size: 0 !important;
    letter-spacing: normal;
    word-spacing: normal;
    & > * {
        display: inline-block;
        @include rem(15px);
    }
}
