$cookie-fontcolor: $c-body;
$cookie-altfontcolor: $c-secondary;
// $cookie-fontsize: 13px;
$cookie-bg: $c-background;
// $cookie-radius: 3px;
$cookie-fg: $c-white;
$cookie-secondary: $c-black;
$cookie-footer-bg: $c-background;
// $cookie-padding: 10px;
// $cookie-overlay: rgba(0,0,0,0.4);
// $cookie-switch-height: 30px;
// $cookie-switch-bg: #FFF;
// $cookie-switch-fg: $cookie-nav-bg;
$cookie-border: $c-border;


@import "cookie-centre/cookie-settings";
@import "cookie-centre/cookie-audit";
@import "cookie-centre/cookie-bar";
@import "cookie-centre/cookie-preferences";

.cookie-preferences {

}

.cookie-bar {

}
.cookie-bar__action--accept,
.cookie-preferences__submit {
    @extend .button;
}
.cookie-audit {
}

.cookie-audit__table + .cookie-audit__title {
    margin-top: 40px;
}

.cookie-preferences__panels {
    min-height: 250px;
}
