.skip-to-main {
    @include lightondark;
    @include hideSafely;

    color: $c-white;
    background: $c-black;

    &:focus {
        @include allowForBars(0, top);

        display: flex;
        left: 0;
        width: 100%;
        padding: 8px $site-gutter;
        z-index: 1001;

        @include breakpoint(tablet) {
            padding: $site-gutter--mobile;
        }
    }

    span {
        padding: 0 8px;
    }
}
