.device {
    background-position: 50% 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.device--laptop {
    background-image: url("/images/devices/laptop_cropped.png");
    padding: (48/1250)*100% (161/1250)*100% (100/1250)*100% (162/1250)*100%;

    .device__inner {
        aspect-ratio: 926 / 560;
        overflow: hidden;
        background: black;

        img {
        }
    }
}


// .page-intro__image {
//     border: 50px solid transparent;
//     border-image-width: (48/707)*100% (162/707)*100% (100/707)*100% (162/707)*100%;
//     border-image: url("/images/devices/laptop_cropped.png");
//     border-image-slice: 48 162 100 162;
//     // border-image-width: 50 50 50 50;
//     border-image-repeat: round;
// }
