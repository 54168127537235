.page-title {
    @include page-title;
}

.large-title {
    @include large-title;
}

.medium-title {
    @include medium-title;
}


.small-title {
    @include small-title;
}
