button {
    width: auto;
    white-space: nowrap;
    vertical-align: top;
    border: none;
}

/* start buttons */
.button {
    @include lightondark;
    @include remove-white-space;

    display: inline-block;
    margin: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: $c-white;
    background: $c-black;
    border-radius: 25px;
    text-align: center;
    transition: background-color .3s ease, color .3s ease, border-color .3s ease;
    white-space: nowrap;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        background-color: $c-brand;
    }

    &[disabled],
    &.button--disabled {
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
    }

    &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0px;
        height: 35px;
    }

    span {
        @include apply-map($f-button);
        @include font(12px, 18px);
        @include mediumText;

        display: inline-block;
        padding: 7px 30px 8px;
        vertical-align: middle;
        white-space: normal;
        letter-spacing: 0.86px;
    }
}

.button--white {
    color: $c-black;
    background: $c-white;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        color: $c-white;
    }
}

.button--blue {
    color: $c-white;
    background: $c-brand;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        background: $c-black;
    }
}

.button--border {
    color: $c-black;
    border: 1px solid $c-black;
    background: transparent;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        color: $c-white;
        border-color: transparent;
    }

    &:after {
        height: 33px;
    }
}

.button--white-border {
    color: $c-white;
    border: 1px solid $c-white;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        border-color: transparent;
    }
}
