.clients-grid {

    &:first-child {
        padding-top: 60px;

        @include breakpoint(tablet) {
            padding-top: 0;
        }
    }

    &__intro {
        // @include font(14px, 35px);
        @include margin(bottom, 115px);

        @include breakpoint(tablet) {
            @include margin(bottom, 45px);

            text-align: center;
        }

        // span {
        //     @include font(25px, 33px);
        //     @include mediumText;

        //     display: block;
        //     margin-top: 5px;

        //     @include breakpoint(tablet) {
        //         @include font(21px, 30px);
        //     }
        // }
    }

    &__content {
        max-width: 1134px;
        margin: 0 auto;

        .grid {
            margin-bottom: -150px;
            margin-left: -134px;

            @include breakpoint(tablet) {
                margin-bottom: -40px;
            }

            > * {
                margin-bottom: 150px;
                padding-left: 134px;

                @include breakpoint(tablet) {
                    margin-bottom: $s-vertical--small;
                }

                &:nth-child(2n) {
                    margin-top: -215px;

                    @include breakpoint(tablet) {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
