@use 'sass:color';

.header-nav {

    @include breakpoint(nav) {
        height: 45px;
        width: 45px;
        border-radius: 50%;
        background: $c-brand;
        display: grid;
        place-items: center;
    }
}

.header-nav__toggle {
    display: none;

    @include breakpoint(nav) {
        display: block;
        position: relative;
        width: 30px;
        height: 30px;
        padding: 0;
        color: $c-white;
        background: transparent;
        border: none;
        cursor: pointer;
        z-index: 1;
    }

    &:before,
    &:after {
        @include breakpoint(nav) {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 22px;
            height: 2px;
            background: currentColor;
            transition: transform .2s ease, height .2s ease, top .2s ease;
            transform-origin: center;
        }

        .header--active & {
            @include breakpoint(nav) {
                width: 30px;
            }
        }
    }

    &:before {

        @include breakpoint(nav) {
            top: 10px;
        }

        .header--active & {

            @include breakpoint(nav) {
                transform: translateX(-50%) rotate(45deg);
                top: 14px;
            }
        }
    }

    &:after {

        @include breakpoint(nav) {
            bottom: 10px;
        }

        .header--active & {

            @include breakpoint(nav) {
                transform: translateX(-50%) rotate(-45deg);
                bottom: 14px;
            }
        }
    }
}

.header-nav__content {
    @include breakpoint(nav) {
        display: none;
    }

    .header--activating & {
        @include breakpoint(nav) {
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 119px $site-gutter--mobile;
            background-color: color.scale($c-black, $alpha: -100%);
            text-align: center;
            overflow: auto;
            transition: background .5s ease, opacity .5s ease;
            opacity: 0;
        }
    }

    .header--active & {
        @include breakpoint(nav) {
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 119px $site-gutter--mobile;
            background: $c-black;
            text-align: center;
            overflow: auto;
            opacity: 1;
        }
    }
}

.header-nav__list {
    display: flex;

    @include breakpoint(nav) {
        display: block;
    }
}

.header-nav__item {
    @include font(14px, 35px);
    @include margin(right, 25px);

    @include breakpoint(nav) {
        @include font(28px, 56px);
        @include lightText;

        margin-right: 0;
    }

    &:last-child {
        margin-left: 50px;
        color: $c-brand;

        a:not(.cta):hover {
            color: $c-black;
        }

        .header:not([data-header="scroll"]) & {
            a:not(.cta):hover {
                color: $c-white;
            }
        }

        @include breakpoint(nav) {
            margin-top: 35px;
            margin-left: 0;
        }
    }

    a {
        display: block;
        text-decoration: none;
        color: inherit;
        border-bottom: 1px solid transparent;

        @include breakpoint(nav) {
            display: inline-block;
        }

        &:not(.cta):hover {
            color: $c-brand;
            @include breakpoint(nav) {
                border-bottom: none;
            }
        }
    }
}

.header-nav__item--active {

    a {
        border-bottom: 1px solid currentColor;

        &:not(.cta):hover {
            color: currentColor;
            @include breakpoint(nav) {
                border-bottom: 1px solid currentColor;
            }
        }
    }
}

.header-nav__sub-content {
    display: none;

    @include breakpoint(nav) {
        @include font(17px, 30px);
        @include regularText;

        display: block;
        color: $c-white;
    }

    p {
        @include margin(bottom, 18px);
    }
}
