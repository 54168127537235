.footer {
    @include lightondark;

    padding: 42px 0 34px;
    color: $c-white;
    background: $c-black;

    // Lift the footer above the 'Apply now' job CTA
    position: relative;
    z-index: 2;

    @include breakpoint(tablet) {
        padding: 46px 0 18px;
    }

    .wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: $frame-max-width;
    }

    a {
        color: inherit;
        text-decoration: none;
        transition: color .2s;

        &:not(.cta):hover {
            color: $c-brand;
        }
    }
}

    .footer__group {
        width: 33.3333%;

        @include breakpoint(tablet) {
            width: 50%;
            text-align: center;
        }
    }

    .footer__group--2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @include breakpoint(tablet) {
            margin-top: 47px;
            order: 2;
        }
    }

    .footer__group--3 {
        text-align: right;

        @include breakpoint(tablet) {
            text-align: center;
        }
    }

    .footer__group--4 {
        @include font(12px, 18px);
        @include mediumText;

        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 47px;

        @include breakpoint(tablet) {
            margin-top: 59px;
            order: 3;
        }
    }

        .footer__title {
            @include font(18px, 24px);
            @include mediumText;
            @include margin(bottom, 18px);

            letter-spacing: 0.6px;

            &:after {
                content: '.';
                color: $c-brand;
            }
        }

        .footer__list {}

            .footer__item {
                @include font(14px, 18px);
                @include margin(bottom, 10px);

                svg {
                    fill: currentColor;
                }
            }

        a.footer__link {
            @include font(18px, 18px);
            @include mediumText;
            @include margin(bottom, 22px);

            color: $c-brand;

            @include breakpoint(tablet) {
                @include margin(bottom, 18px);
            }

            &:not(.cta):hover {
                color: $c-white;
            }
        }
