.job-apply-cta {
    position: fixed;
    z-index: 1;
    bottom: 30px;
    right: calc((100vw - 1400px) / 2 + 80px);

    .button span {
        text-transform: none;
    }

    @include breakpoint(desktop) {
        right: 80px;
    }

    @include breakpoint(tablet) {
        left: 0;
        right: 0;
        bottom: 0;
        padding: 8px 15px;
        background: $c-white;

        .button  {
            display: block;

            span {
                @include font(12px, 18px);
                padding-top: 9px;
                padding-bottom: 9px;
                text-transform: uppercase;
            }
        }
    }

    & + .contact {
        z-index: 2;
    }
}