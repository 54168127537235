.header {
    position: sticky;
    left: 0;
    padding: 27px 0;
    transition: color .2s ease, background .2s ease, padding .2s ease;
    z-index: 3;
    color: $c-black;
    background: $c-white;

    @include breakpoint(nav) {
        padding: 16px 0;
    }

    .wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: $frame-max-width;

        @include breakpoint(nav) {
            padding-right: 16px;
        }
    }
}

.header--static {
    position: fixed;
    left: 0;
    right: 0;
    color: $c-white;
    background: transparent;

    // ~ #main {
    //     margin-top: 110px;

    //     @include breakpoint(nav) {
    //         margin-top: 70px;
    //     }
    // }
}

.header--static-logo {
    @include allowForBars(10px);

    position: absolute;
    z-index: 4;
    left: 0;
    padding-left: 16px;
    width: calc(100vw - 70px);

    // Pin the logo when mobile overlay is active
    .header--activating + & {
        position: fixed;
        background: transparent;
    }

    // For Non-hero headers make sure the logo is the right colour
    .header:not(.header--static) + & {
        .header__logo {
            .icon-d3r__part {
                fill: $c-black;
            }
        }
    }

    .header--activating:not(.header--static) + & {
        .header__logo {
            .icon-d3r__part {
                fill: $c-white;
            }
        }
    }
}

[data-header="scroll"] {
    color: $c-black;
    background: $c-white;
    box-shadow: 0px 3px 20px 2px rgba($c-black, 0.05);

    @include breakpoint(nav) {
        background: none;
        width: fit-content;
        top: 0;
        left: initial;
        right: 0;
        margin-left: auto;
        padding: 16px 0;
        box-shadow: none
    }
}

.header--active {
    @include breakpoint(nav) {
        @include lightondark;

        position: fixed;
        color: $c-white;
        width: 100vw;
    }
}
.header__logo {
    align-self: center;
    @include breakpoint(nav) {
        position: relative;
        z-index: 1;

        [data-header="scroll"]:not(.header--active) & {
            display: none;
        }
    }
}
