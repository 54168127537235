@use 'sass:color';

.hero {
    @include lightondark;
    // @include aspect(1400 / 391);

    position: relative;
    background: $c-black;
    color: $c-white;

    @include breakpoint(tablet) {
        // @include aspect(375 / 311);

        height: auto;
    }

    &--simple {
        height: 190px;
    }
}
.hero__image {
    position: relative;
    font-size: 0;

    img {
        width: 100%;
        height: auto;
        // height: 100%;

        &.lazyloaded {
            // opacity: 0.8;
        }
    }

    &.hero--tint-dark {
        &:after {
            content: '';
            background-color: color.scale($c-black, $alpha: -60%);
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }
}

.hero__video {
    @include aspect(16 / 9);
    font-size: 0;
    position: relative;

    @include breakpoint(mobile) {
        @include aspect(375/391);
    }

    iframe,
    video {
        aspect-ratio: 16 / 9;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        width: auto;
        transform: translate(-50%, -50%);
    }

    video {
        @include breakpoint(mobile) {
            background-image: url("/images/2021/video-poster.jpg?m=2");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

.hero__message-wrapper {
    position: absolute;
    max-width: 90%;
    // width: 100%;
    // height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
    .hero__message {
        display: none;
        text-align: center;
        @include font(25px, 33px);
        font-weight: 500;
        // color:
        //
        &--active {
            display: block;
        }
    }

.hero__content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 900px;
    transform: translate(-50%, -50%);
    text-align: center;

    @include breakpoint(tablet) {
        @include font(21px, 30px);

        padding-right: 30px;
        padding-left: 30px;
    }

    .hero--client &,
    .hero--left & {
        @extend .wrap;

        top: auto;
        bottom: 80px;
        text-align: left;
        transform: translate(-50%, 0);

        @include breakpoint(tablet) {
            bottom: 65px;
            text-align: center;
        }
    }

    .hero--left & {
        bottom: 40px;

        @include breakpoint(laptop) {
            bottom: 10px;
        }

        @include breakpoint(tablet) {
            bottom: 30px;
        }
    }

    .hero--simple & {
        top: 65%;
    }
}

    .hero__title {
        @include font(25px, 33px);
        @include mediumText;
        @include margin(bottom, 40px);

        @include breakpoint(tablet) {
            @include font(21px, 30px);

            letter-spacing: 0.4px;
        }
    }

    .hero__title--small {
        @include font(14px, 35px);
        @include regularText;
        @include margin(bottom, 5px);
    }

    .hero__subtitle {
        @include font(25px, 33px);
        @include mediumText;
        @include margin(bottom, 30px);

        @include breakpoint(tablet) {
            @include font(21px, 30px);
            @include margin(bottom, 10px);

            letter-spacing: 0.4px;
        }
    }

    .hero__logo {
        @include margin(bottom, 40px);

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;

            .hero--client & {
                margin: 0;

                @include breakpoint(tablet) {
                    margin: 0 auto;
                }
            }
        }

        svg {
            fill: white;
            max-width: 100%;
        }
    }

    .hero__text {
        @include font(18px, 30px);

        @include breakpoint(tablet) {
            @include font(17px, 28px);
        }

        &.richtext {
            color: $c-white;
        }

        .hero--client & {
            @include font(16px, 16px);
            @include margin(bottom, 7px);
        }

        .hero--left & {
            max-width: 50%;

            @include breakpoint(tablet) {
                max-width: none;
            }
        }
    }

    .hero__link {
        @include font(14px, 21px);

        display: inline-flex;
        align-items: center;
        color: inherit;
        letter-spacing: 0.6px;

        svg {
            margin-left: 10px;
            fill: currentColor
        }
    }
