@import 'tools/mixins/_tracking';

/// Apply a map of keys to an element
///
/// @param {map} $props
/// @author Neil Brayfield <neil@d3r.com>

@mixin apply-map($props) {
    @each $name in map-keys($props) {
        @if $name == "tracking" {
            @include tracking(map-get($props, $name));
        }
        @else {
            #{$name}: map-get($props, $name)
        }
    }
}
