////
/// Responsive grid
///
/// @author Dom Morgan <dom@d3r.com>

// This is done in the sizings partial now.
// @import '../../../../core/resources/sass/grid';

/// Should the gutter defaults switch by breakpoint or
/// it be done here?
/// @author Dom Morgan <dom@d3r.com>
@mixin grid-wrapper {
    display: block;
    @include grid-gutter;
    @include remove-white-space;
}

@mixin grid-item {
    display: inline-block;
    vertical-align: top;
    // & > :last-child {
    //     &:not(.grid) {
    //         margin-bottom: 0;
    //     }
    // }

}

.grid {
    @include grid-wrapper;

    > * {
        @include grid-item;

        letter-spacing: 0.5px;
    }
}

.grid--flush-bottom {
    // Appropriate negative margins applied via grid-gutter mixin
    overflow: hidden;
}

.grid--flush-bottom-tab {
    @include breakpoint(tablet) {
        // Appropriate negative margins applied via grid-gutter mixin
        overflow: hidden;
    }
}

// Flow items right to left
.grid--reverse {
    direction: rtl;

    > * {
        direction: ltr;
    }
}

.grid--left {
    text-align: left;
}
.grid--center {
    text-align: center;
}
.grid--right {
    text-align: right;
}

// Vertically top
.grid--top {
    > * {
        vertical-align: top;
    }
}
// Vertically centered
.grid--middle {
    > * {
        vertical-align: middle;
    }
}
// Vertically bottom
.grid--bottom {
    > * {
        vertical-align: bottom;
    }
}

.grid--tight {
    @include grid-gutter($grid-gutter-h--tight, $grid-gutter-v--tight);
}
.grid--semi-tight {
    @include grid-gutter($grid-gutter-h--semi-tight, $grid-gutter-v--semi-tight);
}
.grid--semi-loose {
    @include grid-gutter($grid-gutter-h--semi-loose, $grid-gutter-v--semi-loose);
}
.grid--loose {
    @include grid-gutter($grid-gutter-h--loose, $grid-gutter-v--loose);
}
.grid--extra-loose {
    @include grid-gutter($grid-gutter-h--extra-loose, $grid-gutter-v--extra-loose);
}
.grid--flush {
    @include grid-gutter(0, 0);
}
.grid--vflush {
    @include grid-gutter-vertical(0);
}
.grid--hflush {
    @include grid-gutter-horizontal(0);
}
.grid--h-extra-loose {
    @include grid-gutter-horizontal($grid-gutter-h--extra-loose);
}


.grid--listing {
    @include grid-gutter(26px, 58px);

    @include breakpoint(tablet) {
        @include grid-gutter(15px, 23px);
    }
}

.grid--m-semi-tight {
    @include breakpoint(tablet) {
        @include grid-gutter($grid-gutter-h--semi-tight, $grid-gutter-v--semi-tight);
    }
}

.grid--m-flush {
    @include breakpoint(tablet) {
        @include grid-gutter(0, 0);
    }
}

.grid--flex {
    align-items: stretch; // too presumptuous
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    direction: ltr; // let flex-direction deal with this.

    &.grid--left {
        justify-content: flex-start;
    }
    &.grid--center {
        justify-content: center;
    }
    &.grid--right {
        justify-content: flex-end;
    }
    &.grid--reverse {
        flex-direction: row-reverse;
    }
    // These might be superfluous.
    // Maybe flex should always stretch?
    &.grid--top {
        align-items: flex-start;
    }
    &.grid--middle {
        align-items: center;
    }
    &.grid--bottom {
        align-items: flex-end;
    }

    > * {
        display: flex;
        flex: none;
        flex-direction: column;

        // When background styling is on a child element,
        // stretch it up to fill the parent.
        > *:only-child {
            flex: 1 0 auto;
        }
        // Images should never stretch
        > img:only-child {
            flex: none;
        }
    }
}
