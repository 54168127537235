@use 'sass:math';

/* ======================= */
/* = Richtext stylesheet = */
/* ======================= */

/* This should only apply to the wysiwyg */
body.richtext {
    font-size: 100%;
    background: $c-white;
}

.richtext {
    @include apply-map($f-primary);
    @include font(16px, 26px);

    color: $c-body;

    h1 {
        @include large-title;

        margin-bottom: math.div(13em, 25);
    }

    h2 {
        @include large-title;

        margin-bottom: math.div(13em, 25);
    }

    h3 {
        @include medium-title;
        font-weight: 500;
        margin-bottom: math.div(7em, 18);
    }

    h4,
    h5,
    h6 {
        @include small-title;

        margin-bottom: math.div(1em, 16);
    }

    pre,
    p {
        margin-bottom: math.div(40em, 16);

        @include breakpoint(tablet) {
            margin-bottom: math.div(30em, 16);
        }
    }

    h1 + p,
    h2 + p {
        @include font(18px, 28px);

        margin-bottom: math.div(40em, 18);

        @include breakpoint(tablet) {
            margin-bottom: math.div(30em, 18);
        }
    }

    h4 + p,
    h5 + p,
    h6 + p {
        @include font(14px, 24px);

        margin-bottom: math.div(40em, 14);

        @include breakpoint(tablet) {
            margin-bottom: math.div(30em, 14);
        }
    }

    p + p {
        margin-top: math.div(27em, -16);

        @include breakpoint(tablet) {
            margin-top: math.div(17em, -16);
        }
    }

    strong {
        @include mediumText;
    }

    blockquote {
        @include font(25px, 33px);
        @include mediumText;

        text-align: center;

        @include breakpoint(tablet) {
            @include font(21px, 28px);
        }

        p {
            @include margin(bottom, 5px);

            @include breakpoint(tablet) {
                @include margin(bottom, 16px);
            }

            + p {
                margin-top: 0;
            }
        }

        p:last-child:not(:first-child) {
            @include font(18px, 30px);
            @include regularText;

            @include breakpoint(tablet) {
                @include font(17px, 30px);
            }
        }
    }

    ul,
    ol {
        padding: 0;
        margin: 0;
        margin-bottom: math.div(40em, 16);

        @include breakpoint(tablet) {
            margin-bottom: math.div(30em, 16);
            text-align: left;
        }
    }

    ul ul,
    ol ol {
        margin-top: .5em;
    }

    ul {
        list-style: none;

        li {
            position: relative;
            padding-left: 20px;

            &:before {
                content: "\2022";
                position: absolute;
                left: 0;
            }
        }
    }

    ol {
        list-style: decimal outside;
        padding-left: 20px;
    }

    ol ol {
        list-style-type: lower-alpha;
    }

    ol ol ol {
        list-style-type: lower-roman;
    }

    li {
        margin: 0 0 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    dl {
        margin: 0 2em 1em 2.5em;
    }

    dt {
        @include mediumText;

        margin: 1em 0 0.4em 0;

    }

    dd {
        line-height: 1.3em;
        margin-bottom: 0.5em;
    }

    a {
        color: inherit;
    }

    hr {
        border: 0;
        height: 1px;
        background-color: $c-border;
        margin: 1.875em 0;
    }

    blockquote {
        margin: 0 0 1.875em;

        p:last-child {
            margin-bottom: 0;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        table-layout: auto;
        margin-bottom: math.div(40em, 14);

        @include breakpoint(tablet) {
            margin-bottom: math.div(30em, 14);
        }
    }
        th, td {
            text-align: left;
            border: 1px solid black;
            padding: 5px;
        }

    pre {
        white-space: pre;
    }

    code {
        font-family: monospace;
    }
}

.richtext--flush {

    p,
    ul,
    ol {

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.richtext--small {
    @include font(14px, 23px);

    h4,
    h5,
    h6 {
        @include medium-title;

        margin-bottom: math.div(7em, 14);
    }

    p {
        margin-bottom: math.div(40em, 14);

        @include breakpoint(tablet) {
            margin-bottom: math.div(30em, 14);
        }
    }

    h4 + p,
    h5 + p,
    h6 + p {
        margin-bottom: math.div(40em, 14);

        @include breakpoint(tablet) {
            margin-bottom: math.div(30em, 14);
        }
    }

    ul,
    ol {
        margin-bottom: math.div(40em, 14);

        @include breakpoint(tablet) {
            margin-bottom: math.div(30em, 14);
        }
    }
}

.richtext--large {
    @include font(18px, 25px);

    @include breakpoint(tablet) {
        @include font(17px, 28px);
    }

    h4,
    h5,
    h6 {
        @include medium-title;

        margin-bottom: math.div(7em, 18);

        @include breakpoint(tablet) {
            margin-bottom: math.div(7em, 17);
        }
    }

    p {
        margin-bottom: math.div(40em, 18);

        @include breakpoint(tablet) {
            margin-bottom: math.div(30em, 17);
        }
    }

    h4 + p,
    h5 + p,
    h6 + p {
        font-size: inherit;
        line-height: inherit;
        margin-bottom: math.div(40em, 18);

        @include breakpoint(tablet) {
            margin-bottom: math.div(30em, 18);
        }
    }

    ul,
    ol {
        margin-bottom: math.div(40em, 18);

        @include breakpoint(tablet) {
            margin-bottom: math.div(30em, 18);
        }
    }
}
