.icon-d3r {
    display: block;
}

    .icon-d3r__part {
        fill: $c-black;
        transition: fill .3s ease;

        .header--static & {
            fill: $c-white;
        }
        [data-header="scroll"] & {
            fill: $c-black;
        }

        .header--active & {

            @include breakpoint(nav) {
                fill: $c-white;
            }
        }
    }
