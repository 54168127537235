.cta {
    @include font(14px, 21px);
    @include semiBoldText;

    display: inline-block;
    color: inherit;
    background: transparent;
    text-decoration: none;
    letter-spacing: 1px;
    transition: color .3s ease;

    &.cta--hover,
    &:hover {
        color: $c-brand;
    }

    &:not(.cta--with-icon)::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        margin-top: 5px;
        background: currentColor;
    }
}

.cta--with-icon {
    display: inline-flex;
    > .cta__text:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        margin-top: 5px;
        background: currentColor;
    }

    svg {
        margin-left: 15px;
        fill: currentColor;
    }
}

.cta--white {
    @include lightondark;
    
    color: $c-white;
}

.cta--hover-dark {
    &:hover {
        color: $c-black;
    }
}

.cta--icon {
    @include font(18px, 30px);
    @include mediumText;

    display: inline-flex;
    align-items: center;
    letter-spacing: 0.5px;

    &:after {
        display: none;
    }

    svg {
        margin-left: 15px;
        fill: currentColor;
    }
}

.cta--no-border:not(.cta--with-icon)::after {
    content: none;
}
