/// Grid horizontal gutters
///
/// @param {Number} $gutter-x horizontal spacing
/// @author Joe Adcock <joe@d3r.com>

@mixin grid-gutter-horizontal($gutter-x:$grid-gutter-h) {
    margin-left: -($gutter-x);
    > * {
        padding-left: $gutter-x;
    }
}
