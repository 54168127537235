/// Grid gutters
///
/// @param {Number} $gutter-x horizontal spacing
/// @param {Number} $gutter-y vertical spacing
/// @author Brendan Patterson <brendan@d3r.com>

@mixin grid-gutter($gutter-x:$grid-gutter-h, $gutter-y:$grid-gutter-v) {
    margin-left: -($gutter-x);
    > * {
        padding-left: $gutter-x;
        margin-bottom: $gutter-y;
    }
    // Use the correct negative margin for flush bottom
    &.grid--flush-bottom {
        margin-bottom: -($gutter-y);
    }
    &.grid--flush-bottom-tab {
        margin-bottom: -($gutter-y);
    }
    &.grid--flush-bottom-items {
        > *:last-child {
            margin-bottom: 0;
        }
    }
}
