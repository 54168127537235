.client-card {

    .carousel & {
        margin-right: 26px;

        @include breakpoint(phablet) {
            flex: 0 0 auto;
            width: calc(70vw - #{$client-card-carousel-width});
            margin-right: $site-gutter--mobile;
        }
    }
}

    .client-card__link {
        display: block;
        text-decoration: none;
        color: inherit;

        .carousel & {
            width: 500px;

            @include breakpoint(tablet) {
                width: 280px;
            }

            @include breakpoint(phablet) {
                width: auto;
            }

            &:focus,
            &:hover {
                outline: none;
            }
        }

        .carousel--projects & {
            width: calc( ( 1240px - (2 * 26px) ) / 3);

            @include breakpoint(tablet) {
                width: 280px;
            }

            @include breakpoint(phablet) {
                width: auto;
            }
        }
    }

        .client-card__image {
            @include aspect(5 / 6);
            margin-bottom: 25px;

            .client-card--landscape & {
                margin-bottom: 10px;
            }

            &--fivebythree {
                @include aspect(5 / 3);
            }
            &--fourbythree {
                @include aspect(4 / 3);
            }
            @include breakpoint(tablet) {
                margin-bottom: 18px;
            }

            .block--clients-list & {
                @include aspect(396 / 238);
            }

            img {
                width: 100%;
                height: auto;
                transition: transform .5s ease;

                .client-card__link:focus &,
                .client-card__link:hover & {
                    transform: scale(1.04);
                }
            }
        }

        .client-card__title {
            @include large-title;

            .client-card--landscape &,
            .carousel & {
                @include medium-title;
                @include mediumText;
            }

            .client-card__link:focus &,
            .client-card__link:hover & {
                color: $c-brand;
            }
        }

        .client-card__text {
            @include font(16px, 26px);

            margin-top: 10px;

            @include breakpoint(tablet) {
                @include font(15px, 26px);

                letter-spacing: 0.4px;
            }
        }
